/**
* Generated automatically at built-time (2024-10-08T07:49:00.799Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-winekeeper-vinkyl",templateKey: "sites/88-bea420a1-83ab-4552-b3c9-e50e45045f6e"};