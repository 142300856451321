/**
* Generated automatically at built-time (2024-10-08T07:49:00.780Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-winecave-vinkyl",templateKey: "sites/88-facb3b52-acde-480e-aa88-134103474cb3"};